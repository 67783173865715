export interface NotificationOptions {
  type?: string;
  link?: string;
  linkText?: string;
  timeout?: number;
}

/**
 * Notification service for displaying user notifications
 */
export class Notification {
  static displayTime = 3000;

  // Public methods

  /**
   * Display a success notification
   * @param {string} message The message to show, supports HTML
   * @param {object=} options Optional settings for the notification
   * @param {string=} options.link URL of a link to be displayed
   * @param {string=} options.linkText Link text to display
   * @param {number=} options.timeout Set the amount of milliseconds to wait until hiding the notifications. Set to false to disable hiding.
   * @returns {undefined}
   */
  static success(message: string, options: NotificationOptions = {}) {
    options.type = 'positive';
    this._notify(message, options);
  }

  /**
   * Display an error notification
   * @param {string} message The message to show, supports HTML
   * @param {object=} options Optional settings for the notification
   * @param {string=} options.link URL of a link to be displayed
   * @param {string=} options.linkText Link text to display
   * @param {number=} options.timeout Set the amount of milliseconds to wait until hiding the notifications. Set to false to disable hiding.
   * @returns {undefined}
   */
  static error(message: string, options: NotificationOptions = {}) {
    options.type = 'negative';
    this._notify(message, options);
  }

  /**
   * Display an info notification
   * @param {string} message The message to show, supports HTML
   * @param {object=} options Optional settings for the notification
   * @param {string=} options.link URL of a link to be displayed
   * @param {string=} options.linkText Link text to display
   * @param {number=} options.timeout Set the amount of milliseconds to wait until hiding the notifications. Set to false to disable hiding.
   * @returns {undefined}
   */
  static info(message: string, options: NotificationOptions = {}) {
    options.type = 'info';
    this._notify(message, options);
  }

  /**
   * Display a warning notification
   * @param {string} message The message to show, supports HTML
   * @param {object=} options Optional settings for the notification
   * @param {string=} options.link URL of a link to be displayed
   * @param {string=} options.linkText Link text to display
   * @param {number=} options.timeout Set the amount of milliseconds to wait until hiding the notifications. Set to false to disable hiding.
   * @returns {undefined}
   */
  static warn(message: string, options: NotificationOptions = {}) {
    options.type = 'warning';
    this._notify(message, options);
  }

  // Private methods

  /**
   * Create and append a notification
   * @private
   * @param {string} message Message to display
   * @param {object=} options Options object
   * @returns {undefined}
   */
  static _notify(message: string, options: NotificationOptions = {}) {
    const notificationElem = this._createNotification(message, options);
    this._appendNotification(notificationElem);
  }

  /**
   * Creates the notification list container element
   * @private
   * @returns {undefined}
   */
  static _createList() {
    let container = Notification.getContainer();
    if (!container) {
      container = document.createElement('div');
      container.classList.add('notifications-container', 'notifications-top-right');
      document.body.appendChild(container);
    }
    return container;
  }

  static getContainer() {
    const containers = document.getElementsByClassName('notifications-container');
    return containers && containers.length > 0 ? containers[0] : false;
  }

  /**
   * Create notification HTML Element
   * @private
   * @param {string} message Notification message
   * @param {object=} options Notification options
   * @returns {HTMLElement} HTML element for the notification
   */
  static _createNotification(message: string, options: NotificationOptions): Node {
    const markup = Notification._createNotificationMarkup(message, options);

    const frag = document.createRange().createContextualFragment(markup);
    const elem = frag.firstChild;
    Notification._eventBinding(elem, options);

    return elem;
  }

  static _createNotificationMarkup(message: string, options: NotificationOptions) {
    const link =
      options.link && options.linkText ? `<a class="link" href="${options.link}">${options.linkText}</a>` : '';
    return `<div class="notification notification-${options.type}">
    <span class="message">${message}</span>
    ${link}
    <a class="close">&times;</a>
  </div>`;
  }

  /**
   * Bind mouse events to the notification element
   * @private
   * @param {HTMlElement} elem Notification element
   * @param {options=} options Notification options
   * @returns {undefined}
   */
  static _eventBinding(elem, options: NotificationOptions) {
    if (elem) {
      elem.addEventListener('click', event => {
        event.stopPropagation();
        this._removeNotification(elem);
      });

      this._createTimeout(elem, options.timeout);
      elem.addEventListener('mouseover', () => this._clearTimeout(elem));
      elem.addEventListener('mouseout', () => this._createTimeout(elem, options.timeout));

      // TODO future functionality to add backbut without CXOS specific logic.
      // const link = elem.querySelector('.message + a.link');
      // if (link) {
      //   link.addEventListener('click', event => {
      //     // If the link starts with the CxOS host and the link originates from within the CxOS app
      //     // Publish a router change event for the app to pick up
      //     // const host = Utils.cxosServiceHost();
      //     // if (link.href.indexOf(host) === 0 && link.baseURI.indexOf(host) === 0) {
      //     //   Cx.Events.publish('route.change', link.href.replace(host, ''));
      //     //   event.preventDefault();
      //     // }
      //   });
      // }
    }
  }

  /**
   * Remove the notification from the DOM
   * @private
   * @param {HTMLElement} elem Element to be removed
   * @returns {void}
   */
  static _removeNotification(elem: HTMLElement): void {
    if (elem) {
      this._clearTimeout(elem);
      elem.parentNode.removeChild(elem);
    }
  }

  /**
   * Append notification element to the DOM
   * @private
   * @param {HTMLElement} elem Notification element
   * @returns {void}
   */
  static _appendNotification(elem): void {
    const container = this._createList();
    container.appendChild(elem);
  }

  /**
   * Create timeout for automatically clearing the notification. The timeout is added to the HTML element for easy tracking.
   * @private
   * @param {HTMLElement} elem Notification element
   * @param {number=} timeout Custom timeout duration
   * @returns {undefined}
   */
  static _createTimeout(elem, timeout: number) {
    if (elem && timeout > -1) {
      elem.timeout = setTimeout(() => this._animateRemove(elem), timeout || this.displayTime);
    }
  }

  /**
   * Clear the notification timeout
   * @private
   * @param {HTMLElement} elem Notification element
   * @returns {undefined}
   */
  static _clearTimeout(elem) {
    if (elem && elem.timeout) {
      clearTimeout(elem.timeout);
    }
  }

  /**
   * Animate the removing of a notification
   * @private
   * @param {HTMLElement} elem Notification element
   * @returns {undefined}
   */
  static _animateRemove(elem) {
    if (elem) {
      elem.addEventListener('animationend', () => this._removeNotification(elem));
      elem.classList.add('remove');
    }
  }
}
